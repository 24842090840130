import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import { Title, StyledButtonLink } from "../components/elements";

const Container = styled.div`
  text-align: center;
  padding: 0 16px;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header />
    <Container>
      <Title>NOT FOUND</Title>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <StyledButtonLink to="/">Go back home</StyledButtonLink>
    </Container>
    <Footer />
  </Layout>
);

export default NotFoundPage;
